.quick-digit-header-status-indicator{
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  background-color: #3C974C;
  box-shadow: 0px 0px 5.5px 0px #3C974C;
}

.quick-digit-header-status-indicator.status-drawing {
  background-color: #FF7E2B;
  box-shadow: 0px 0px 5.5px 0px #FF7E2B;
}
