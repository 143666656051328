
.quick-digit-number-item-container .list_box[data-v-03806bc5] {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .0625rem;
}

.quick-digit-number-item-container .num{
  background-repeat: no-repeat;
  width: 1.875rem;
  height: 2.75rem;
  background-size: 18.75rem 2.75rem;
}


.slot_numbers_wrap .shadow[data-v-03806bc5] {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

.animate-scroll[data-v-03806bc5] {
  animation: move-03806bc5 0.4s calc(var(--delay) * 1s) linear infinite;
}

.animate-bounce[data-v-03806bc5] {
  animation: move-03806bc5 0.4s linear infinite, bounce-into-03806bc5 2s calc(
        var(--delay) * 1s
      ) forwards;
}

.animate-quick-reset[data-v-03806bc5] {
  animation: move-03806bc5 0.4s calc(var(--delay) * 3s) linear;
}

.border-animate[data-v-03806bc5] {
  animation: enhance-bounce-into 1s calc(var(--delay) * 1s) forwards;
}

.list_box[data-v-03806bc5] {
  color: #fff;
  transform: translateY(calc(var(--i) * -8.3333% + 1px));
}

@keyframes move-03806bc5 {
  0% {
    transform: translateY(-90%);
  }

  to {
    transform: translateY(-1%);
  }
}

@keyframes bounce-into-03806bc5 {
  0% {
    transform: translateY(calc(var(--i) * -8.32%));
  }

  10% {
    transform: translateY(calc(var(--i) * -8.32% + 12%));
  }

  25% {
    transform: translateY(calc(var(--i) * -8.32% - 8%));
  }

  40% {
    transform: translateY(calc(var(--i) * -8.32% + 8%));
  }

  55% {
    transform: translateY(calc(var(--i) * -8.32% - 4%));
  }

  65% {
    transform: translateY(calc(var(--i) * -8.32% + 4%));
  }

  75% {
    transform: translateY(calc(var(--i) * -8.32% - 2%));
  }

  82% {
    transform: translateY(calc(var(--i) * -8.32% + 2%));
  }

  90% {
    transform: translateY(calc(var(--i) * -8.32% - 1%));
  }

  95% {
    transform: translateY(calc(var(--i) * -8.32% + 0%));
  }

  to {
    transform: translateY(calc(var(--i) * -8.33333% ));
  }
}
